royalslider = {

  init: function () {

    $('#ad-slider').royalSlider({
      slidesSpacing: 0,
      arrowsNav: true,
      loop: true,
      keyboardNavEnabled: true,
      controlsInside: false,
      imageScaleMode: 'fill',
      arrowsNavAutoHide: false,
      autoScaleSlider: false,
      controlNavigation: 'bullets',
      thumbsFitInViewport: false,
      navigateByClick: true,
      startSlideId: 0,
      transitionType: 'move',
      globalCaption: false,
      deeplinking: {
        enabled: true,
        change: false
      },
      autoPlay: {
        enabled: true,
        pauseOnHover: false,
        delay:12000
      },
      /* size of all images http://help.dimsemenov.com/kb/royalslider-jquery-plugin-faq/adding-width-and-height-properties-to-images */
      imgWidth: 1920,
      imgHeight: 1080
    })

  }
}