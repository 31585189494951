masonry = {
  settings: {
    grid: $('.js-masonry')
  },
  init: function(){
    masonry.settings.grid.masonry({
      itemSelector: '.o-col',
      percentPosition: true
    })
  }
}