mobile = {
  settings: {
    button: $('.js-nav-toggle'),
    body: $('.js-body'),
    toggle: $('.js-subnav-toggle')
  },
  init: function(){
    mobile.settings.button.click(function(e){
      e.preventDefault();
      mobile.settings.body.toggleClass('nav-mobile-is-active');
    });

    mobile.settings.toggle.click(function(e){
      e.preventDefault();
      $(this).toggleClass('is-active');
      $(this).next('.js-subnav').toggleClass('is-active');
    });
  }
}