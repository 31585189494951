var responsiveVideo = function(el) {
  this.el = document.querySelectorAll(el);

  this.init = function() {
    this.el.forEach(function(el) {
      // Detect API
      var api = this.detectAPI(el.src);

      // Add wrapper
      this.addWrapper(el, api);
    }.bind(this));
  };

  this.detectAPI = function(src) {
    if(src.includes('youtube')) {
      return 'youtube';
    }
  };

  this.addWrapper = function(el, api) {
    switch (api) {
      case 'youtube':
        // Create wrapper
        var wrapper = document.createElement('div');
            wrapper.className = 'c-video-youtube';

        // Add it to the DOM
        el.parentNode.insertBefore(wrapper, el);

        // Insert element into wrapper
        wrapper.appendChild(el);
        break;
    }
  };
};