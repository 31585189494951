productsFilter = {
  settings: {
    form: $('.js-productsFilter'),
    container: $('.js-productsFilterContainer'),
    button: $('.js-productsFilterButton'),
    total: parseInt($('.js-productsFilterTotal').html()),
    filter: null,
    start: 6,
    offset: 6,
    step: 2
  },
  init: function(){
    console.log("init");
    if(productsFilter.settings.offset >= productsFilter.settings.total){
      productsFilter.settings.button.hide();
    }
    productsFilter.settings.form.find('input').on('change', function(){
      $.ajax({
        type: "post",
        url: productsFilter.settings.form.attr('action'),
        data: productsFilter.settings.form.serialize(),
        dataType: 'html',
        success: function(response) {
          productsFilter.settings.button.show();
          productsFilter.settings.container.html(response);
          productsFilter.settings.filter = productsFilter.settings.form.serialize();
          productsFilter.settings.offset = productsFilter.settings.start;
          productsFilter.settings.total = $('.js-productsFilterTotal').html();
          productsFilter.settings.total = parseInt($('.js-productsFilterTotal').html());
          if(productsFilter.settings.offset >= productsFilter.settings.total){
            productsFilter.settings.button.hide();
          }
        }
      });
    })
  },
  update: function(){
    $.ajax({
      type: "post",
      url: productsFilter.settings.form.attr('action'),
      data: productsFilter.settings.filter+'&'+$.param({
        'step': productsFilter.settings.step,
        'offset': productsFilter.settings.offset
      }),
      dataType: 'html',
      success: function(response) {
        productsFilter.settings.container.append(response);
        productsFilter.settings.offset += productsFilter.settings.step;

        if(productsFilter.settings.offset >= productsFilter.settings.total){
          productsFilter.settings.button.hide();
        }
      }
    });
  }
}