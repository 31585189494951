bissubnav = {
  settings: {
    navItemBis: $('.js-nav-primary-bis'),
    navItemBisSubnav: '.js-nav-primary-bis-subnav',
  },
  init: function(){
    bissubnav.settings.navItemBis.hover(function(){
      $(this).closest('li').find(bissubnav.settings.navItemBisSubnav).toggleClass('is-active');
    });
  }
}