trainingsFilter = {
  settings: {
    form: $('.js-trainingsFilter'),
    container: $('.js-trainingsFilterContainer'),
    button: $('.js-trainingsFilterButton'),
    resetFilterButton: $('.js-trainingResetFilterButton'),
    total: parseInt($('.js-trainingsFilterTotal').html()),
    filter: null,
    start: 6,
    offset: 6,
    step: 2
  },
  init: function(){
    console.log("init Filters");
    if(trainingsFilter.settings.offset >= trainingsFilter.settings.total){
      trainingsFilter.settings.button.hide();
    }
    trainingsFilter.settings.form.find('input').on('change', function(){
      $.ajax({
        type: "post",
        url: trainingsFilter.settings.form.attr('action'),
        data: trainingsFilter.settings.form.serialize(),
        dataType: 'html',
        success: function(response) {
          trainingsFilter.settings.button.show();
          trainingsFilter.settings.container.html(response);
          trainingsFilter.settings.filter = trainingsFilter.settings.form.serialize();
          trainingsFilter.settings.offset = trainingsFilter.settings.start;
          trainingsFilter.settings.total = $('.js-trainingsFilterTotal').html();
          trainingsFilter.settings.total = parseInt($('.js-trainingsFilterTotal').html());
          if(trainingsFilter.settings.offset >= trainingsFilter.settings.total){
            trainingsFilter.settings.button.hide();
          }
        }
      });
    })
  },
  update: function(){
    $.ajax({
      type: "post",
      url: trainingsFilter.settings.form.attr('action'),
      data: trainingsFilter.settings.filter+'&'+$.param({
        'step': trainingsFilter.settings.step,
        'offset': trainingsFilter.settings.offset
      }),
      dataType: 'html',
      success: function(response) {
        trainingsFilter.settings.container.append(response);
        trainingsFilter.settings.offset += trainingsFilter.settings.step;

        if(trainingsFilter.settings.offset >= trainingsFilter.settings.total){
          trainingsFilter.settings.button.hide();
        }
      }
    });
  },
  reset: function(){
    $('input[type=radio]').prop('checked', false);
    if(trainingsFilter.settings.offset >= trainingsFilter.settings.total){
      trainingsFilter.settings.button.hide();
    }
    $.ajax({
      type: "post",
      url: trainingsFilter.settings.form.attr('action'),
      data: trainingsFilter.settings.form.serialize(),
      dataType: 'html',
      success: function(response) {
        trainingsFilter.settings.button.show();
        trainingsFilter.settings.container.html(response);
        trainingsFilter.settings.filter = trainingsFilter.settings.form.serialize();
        trainingsFilter.settings.offset = trainingsFilter.settings.start;
        trainingsFilter.settings.total = $('.js-trainingsFilterTotal').html();
        trainingsFilter.settings.total = parseInt($('.js-trainingsFilterTotal').html());
        if(trainingsFilter.settings.offset >= trainingsFilter.settings.total){
          trainingsFilter.settings.button.hide();
        }
      }
    });
  }
}